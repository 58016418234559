export default [
  {
    divider: "Organização",
    children: [
      {
        title: "Perfil",
        icon: "mdi-view-dashboard",
        to: "admin.profile",
        permission: 256,
      },
      {
        title: "Membros",
        icon: "mdi-account-group",
        to: "admin.members",
        permission: 8,
      },
    ],
  },
  {
    divider: "Gerenciar",
    children: [
      {
        title: "Eventos",
        icon: "mdi-party-popper",
        to: "admin.parties",
        permission: [2, 16, 64, 128, 512, 8192],
        allowOffline: true,
      },
      {
        title: "Financeiro",
        icon: "mdi-cash-multiple",
        to: "admin.transactions",
        permission: [2048, 4096],
      },
      // {
      //   title: "Promoções",
      //   icon: "mdi-ticket-percent",
      //   to: "admin.promotions",
      //   permission: [65536],
      // },
      {
        title: "POS",
        icon: "mdi-printer-pos",
        to: "admin.pos",
        permission: [268435456, 536870912, 1073741824],
      },
      {
        title: "Entrada",
        icon: "mdi-qrcode",
        to: "admin.entrance",
        permission: [131072, 262144],
      },
      {
        title: "Filiações",
        icon: "mdi-card-account-details-star",
        to: "admin.membership",
        permission: [4194304, 8388608],
      },
      {
        title: "Minhas vendas",
        icon: "mdi-account-cash",
        to: "admin.my-sales",
        permission: [32, 1024],
      },
    ],
  },
  {
    divider: "Extras",
    children: [
      {
        title: "Fornecedores", //beta
        subtitle: "Produtos e serviços",
        icon: "mdi-shopping",
        to: "admin.extra.catalog",
        permission: [256, 16, 64, 512, 2048, 4194304, 8388608],
        flags: { catalog_suppliers: true },
      },

      // {
      //   title: "Simulador de Custos", //beta
      //   icon: "mdi-cash-multiple",
      //   to: "admin.extra.cost-simulator",
      //   permission: [16],
      // },
    ],
  },
];
